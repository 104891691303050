//财务管理api

import axios from '@/utils/request'
// 获取充值记录/收支明细列表
export const getRecordList = (params)=>{
 return axios.request({
    url: 'agent/bill/index',
    method: 'get',
    params
  })
};
export const getBrandBillsList = function(params) {
	return axios.request({
		url: 'brand/bill/index',
		method: 'get',
		params
	})
};
export const getAgentBillsType = function(params) {
	return axios.request({
		url: 'agent/bill/billTypes',
		method: 'get',
		params
	})
};
export const getBrandBillsType = function(params) {
	return axios.request({
		url: 'brand/bill/billTypes',
		method: 'get',
		params
	})
};
// 充值
export const setAgentRecharge = (data)=>{
  return axios.request({
     url: 'agent/bill/recharge',
     method: 'post',
     data
   })
 };
// 扣款
export const setAgentCutcharge = (data)=>{
  return axios.request({
     url: 'agent/bill/deduct',
     method: 'post',
     data
   })
 };
 //平台分账汇总
 export const getBanlanceSummary = function(params) {
	return axios.request({
		url: 'platform/banlanceSummary',
		method: 'get',
		params
	})
};
//平台收入明细列表
export const getPlatformBanlance = function(params) {
	return axios.request({
		url: 'platform/banlance',
		method: 'get',
		params
	})
};
//平台提现明细列表
export const getPlatformCashLog = function(params) {
	return axios.request({
		url: 'platform/cashLog',
		method: 'get',
		params
	})
};
//平台分账明细列表
export const getPlatformSettlementLog = function(params) {
	return axios.request({
		url: 'platform/settlementLog',
		method: 'get',
		params
	})
};
// 提现
export const setPlatformCash = (data)=>{
  return axios.request({
     url: 'platform/cash',
     method: 'post',
     data
   })
 };

 //用户余额
 export const balanceList = function(params) {
	return axios.request({
		url: 'balance/index',
		method: 'get',
		params
	})
};
 //短信充值记录
 export const getRechargeRecordList = function(params) {
	return axios.request({
		url: 'v2/sms',
		method: 'get',
		params
	})
};
