<template>
    <a-card :bordered="false">
        <SmallSquare content="查询步骤
                              1.选择品牌
                              2.选择角色，分销员、经销商、门店
                              3.如果选择的分销员角色，则输入分销员的会员ID；选择的是经销商，则输入经销商编号或手机号；选择的是门店，则输入门店编号或手机号" />
        <a-form layout='inline' class="m-t-20" :model="search">
            <a-form-item label="品牌商" class="s_po_re">
                <a-input v-model:value="search.brand_name" placeholder="请选择品牌" readonly style="width:260px"></a-input>
                <CloseCircleOutlined class="CloseCircleOutlined" v-show="search.brand_name"
                    @click="search.brand_name='';search.brand_id=''" />
            </a-form-item>
            <a-form-item>
                <a-button type="primary" @click="openBrand()">选择</a-button>
            </a-form-item>
            <a-form-item label="角色">
                <a-select v-model:value="search.type" style="width:120px" placeholder="请选择角色">
                    <!-- <a-select-option value="">全部</a-select-option> -->
                    <a-select-option :value="1">分销员</a-select-option>
                    <a-select-option :value="3">经销商</a-select-option>
                    <a-select-option :value="2">门店</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="用户ID">
                <a-input v-model:value="search.uid" placeholder="请输入用户ID进行搜索" style="width:260px"></a-input>
            </a-form-item>
            <a-form-item>
                <a-button type="primary" @click="onSearch()">搜索</a-button>
            </a-form-item>
        </a-form>
        <a-table class="m-t-20" :pagination='false' :loading='listLoading' bordered rowKey='id'
            :columns='columns' :data-source='list' @change="changePage"></a-table>

        <a-modal v-model:visible="showVisible" :width="1000" title="选择品牌商" centered destroyOnClose :footer="null">
            <a-form layout='inline' class="m-b-20">
                <a-form-item label="关键词" class="s_po_re">
                    <a-input v-model:value="search_brand.keywords" placeholder="请输入关键词进行搜索" style="width:220px">
                    </a-input>
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" @click="search_brand.page=1;getBrand();">搜索</a-button>
                </a-form-item>
            </a-form>
            <a-table :loading='brand_loading' :columns='brand_columns' :data-source='brand_list' rowKey='id' bordered
                :pagination="brand_pagination" @change="changeBrandPage">
                <template v-slot:action="{record}">
                    <a-button type="primary" :ghost="!record.checked" @click="selectBrand(record)">
                        {{record.checked?'已选择':'选择'}}</a-button>
                </template>
            </a-table>
        </a-modal>
    </a-card>
</template>
<script>
    import { balanceList } from '@/api/financial.js';
    import { getBrandtList } from "@/api/brand"
    import { CloseCircleOutlined } from '@ant-design/icons-vue';
    import { reactive, toRefs, ref } from 'vue';
    import { $iscode } from "@/utils/app";
    import { message } from 'ant-design-vue';
    const brand_columns = [
        {
            title: "品牌商编号",
            dataIndex: "brand_no",
        },
        {
            title: "品牌商名称",
            dataIndex: "brand_name",
        },
        {
            title: "公司名称",
            dataIndex: "company",
        },
        {
            title: "品牌商账号",
            dataIndex: "account",
        },
        {
            title: "操作",
            dataIndex: "action",
            width: 120,
            slots: { customRender: 'action' }
        }
    ]

    const columns = [
        {
            title: "用户ID",
            dataIndex: "user",
        },
        {
            title: "账号余额",
            dataIndex: "money",
            width: 320
        },
    ]
    export default {
        components: { CloseCircleOutlined },
        setup() {

            const search = ref({
                uid: '',
                type: null,
                page: 1,
                limit: 10,
                brand_name: '',
                brand_id: ''
            })

            const search_brand = ref({
                page: 1,
                limit: 5,
                keywords: ''
            })

            const state = reactive({
                showVisible: false,
                pagination: {
                    total: 0,
                    current: 1,
                    pageSize: 10,
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '15', '20', '25'],
                    showQuickJumper: true,
                    showTotal: (total) => `共 ${total} 条`,
                },
                brand_pagination: {
                    total: 0,
                    current: 1,
                    pageSize: 5,
                    defaultPageSize: 5,
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '15', '20', '25'],
                    showQuickJumper: true,
                    showTotal: (total) => `共 ${total} 条`,
                },
                brand_loading: false,
                listLoading: false,
                loading: false,
                brand_list: [],
                list: []
            })
            const methods = {
                changePage(e) {
                    search.value.limit = e.pageSize;
                    search.value.page = e.current;
                    state.pagination.current = e.current;
                    state.pagination.pageSize = e.pageSize;
                    methods.getList()
                },
                async getList() {
                    state.loading = true;
                    state.list = [];
                    let res = await balanceList({ ...search.value }).then(res => res.data)
                    state.loading = false;
                    if ($iscode(res)) {
                        state.list.push({
                            user: search.value.uid,
                            money: "￥" + res.data.now_money
                        })
                    } else {
                        message.error(res.msg)
                    }

                },
                async getBrand() {
                    state.brand_loading = true;
                    state.list = [];
                    let res = await getBrandtList({ ...search_brand.value }).then(res => res.data)
                    state.brand_loading = false;
                    if ($iscode(res)) {
                        state.brand_list = res.data.data || [];
                        if (state.brand_list.length != 0) {
                            state.brand_list.forEach(v => {
                                v.checked = false;
                                if (search.value.brand_id == v.id) {
                                    v.checked = true;
                                }
                            })
                        }
                        state.brand_pagination.current = res.data.current_page || 1;
                        search_brand.value.page = res.data.current_page || 1;
                        state.brand_pagination.total = res.data.total || 0;
                    } else {
                        message.error(res.msg)
                    }
                },
                openBrand() {
                    search_brand.value.page = 1;
                    state.brand_pagination.current = 1;
                    methods.getBrand()
                    state.showVisible = true;
                },
                selectBrand(record) {
                    record.checked = true;
                    search.value.brand_name = record.brand_name;
                    search.value.brand_id = record.brand_no;
                    state.showVisible = false;
                },
                changeBrandPage(e) {
                    search_brand.value.limit = e.pageSize;
                    search_brand.value.page = e.current;
                    state.brand_pagination.current = e.current;
                    state.brand_pagination.pageSize = e.pageSize;
                    methods.getBrand()
                },
                async onSearch() {
                    if (!search.value.brand_id) return message.error('请选择品牌商!')
                    if (!search.value.type) return message.error('请选择角色!')
                    if (!search.value.uid) return message.error('请输入用户ID!')
                    const hide = message.loading('加载中...');
                    await methods.getList()
                    setTimeout(hide)
                }
            }


            return {
                ...toRefs(state),
                search,
                ...methods,
                brand_columns,
                columns,
                search_brand
            }
        },
    }
</script>
<style lang="less" scoped>
    .CloseCircleOutlined {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 8PX;
    }
</style>